import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import Parse from 'parse';
// import Upload from './upload.component';
import {
  Grid,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  useMediaQuery,
  Box,
  Slider,
  useTheme,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import { QRCode } from 'lifetaggerQrCode';
import { useSpring, animated } from '@react-spring/web';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const LifeCodeCreator = (props) => {
  const theme = useTheme();

  const [beaconId] = useState(uuid().toUpperCase());
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('/images/Layer_2@3x.png');
  const [fgColor, setFgColor] = useState('#262626');
  const [bgColor, setBgColor] = useState('#f4f4f4');
  const [qrStyle, setQrStyle] = useState('dots');
  const [eyeRadius, setEyeRadius] = useState(0);
  const [type] = useState('qrcode');
  const [shortenedUrl, setShortenedUrl] = useState('');
  const [isExpanded, setIsExpanded] = useState(false); // State for managing collapse

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Fade-in & slide animation for headline
  const headlineSpring = useSpring({
    opacity: 1,
    transform: 'translateY(0px)',
    from: { opacity: 0, transform: 'translateY(-30px)' },
    config: { tension: 200, friction: 15 },
  });

  // Background animation
  const bgSpring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { tension: 120, friction: 14 },
  });

  // QR Code fade-in effect
  const qrSpring = useSpring({
    opacity: isExpanded ? 1 : 0,
    transform: isExpanded ? 'scale(1)' : 'scale(0.5)',
    config: { tension: 200, friction: 20 },
  });

  useEffect(() => {
    const fetchUrl = async () => {
      const response = await fetch(
        `https://tinyurl.com/api-create.php?url=${process.env.REACT_APP_URL}/qrtags/${beaconId}`
      );
      const url = await response.text();
      setShortenedUrl(url);
    };

    fetchUrl();
  }, [beaconId]);

  const handleFileUpload = async (files) => {
    const parseImage = new Parse.File('image', files[0], files[0].type);
    await parseImage.save();
    setImage(parseImage.url());
  };

  return (
    <animated.div style={bgSpring}>
      {/* Background with doodles */}
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #1a1f36, #2c3e50)',
          position: 'relative',
          textAlign: 'center',
          py: 5,
        }}
      >
        {/* Doodles (SVG or CSS pseudo-elements) */}
        <Box
          sx={{
            position: 'absolute',
            top: '10%',
            left: '5%',
            width: '100px',
            height: '100px',
            backgroundImage: "url('/images/wavyline.svg')",
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            opacity: 0.3,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '10%',
            right: '10%',
            width: '120px',
            height: '120px',
            backgroundImage: "url('/images/triangledoodle.svg')",
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            opacity: 0.3,
          }}
        />

        <Grid
          container
          justifyContent="center"
          style={{ padding: '20px' }}
          spacing={2}
        >
          <Box
            sx={{
              textAlign: 'center',
              padding: '40px 20px',
              marginBottom: '40px',
            }}
          >
            {/* Animated Headline */}
            <animated.div style={headlineSpring}>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 'bold',
                  color: '#FFCC00',
                  fontSize: isMobile ? '1.8rem' : '3rem', // Dynamic Font Size
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  lineHeight: 1.3,
                  padding: '20px',
                  display: 'inline-block',
                  maxWidth: '100%',
                  margin: 'auto',
                  background: 'rgba(255, 255, 255, 0.06)',
                  borderRadius: '10px',
                  backdropFilter: 'blur(2px)',
                }}
              >
                QR CODES THAT ARE
                <strong> DYNAMIC, SMART,</strong> <br />
                AND JUST PLAIN BETTER.
              </Typography>
            </animated.div>
            {/* Subheadline */}
            <Typography variant="h5" sx={{ color: 'white', mt: 2 }}>
              Try for free for 14 days!
            </Typography>
          </Box>
          {!isExpanded && (
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              sx={{
                mt: 4,
                p: 3,
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '20px',
                maxWidth: '80%',
                margin: 'auto',
                backdropFilter: 'blur(10px)',
              }}
            >
              {/* Left: CTA */}
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 'bold', mb: 2, color: 'white' }}
                >
                  Create a free LifeCode
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: '#FFCC00',
                    color: '#121212',
                    textTransform: 'none',
                    fontSize: '16px',
                    px: 3,
                    py: 1,
                    '&:hover': { bgcolor: '#ffdb4d' },
                  }}
                  onClick={() => setIsExpanded(true)}
                >
                  Get your LifeCode for free →
                </Button>
              </Grid>

              {/* Right Column - Image Section */}
              {/* Right: QR Code Image */}
              <Grid item xs={12} md={6}>
                <animated.img
                  src="/images/lifecodeforsite.png"
                  alt="LifeCode Example"
                  style={{
                    maxWidth: '60%',
                    height: 'auto',
                    borderRadius: '10px',
                  }}
                />
              </Grid>
            </Grid>
          )}

          {isExpanded && (
            <animated.div style={qrSpring}>
              <Grid
                item
                xs={12}
                md={10}
                sx={{
                  mt: 4,
                  p: 3,
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '20px',
                  maxWidth: '80%',
                  margin: 'auto',
                  backdropFilter: 'blur(10px)',
                }}
                justifyContent={'center'}
                justifyItems={'center'}
                alignSelf={'center'}
              >
                <Grid container spacing={2}>
                  {/* Main Layout */}
                  <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    md={12}
                    spacing={2}
                    sx={{ mb: 3 }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6" sx={{ color: 'white', mt: 3 }}>
                        Describe Your LifeCode
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Name Your LifeCode"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        size="medium"
                        sx={{
                          bgcolor: 'rgba(255, 255, 255, 0.2)',
                          borderRadius: '5px',
                          input: { color: 'white' },
                          '& label': { color: 'white' },
                          '& label.Mui-focused': { color: '#FFCC00' },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: 'white' },
                            '&:hover fieldset': { borderColor: '#FFCC00' },
                            '&.Mui-focused fieldset': {
                              borderColor: '#FFCC00',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Describe Your LifeCode"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        size="medium"
                        sx={{
                          bgcolor: 'rgba(255, 255, 255, 0.2)',
                          borderRadius: '5px',
                          input: { color: 'white' },
                          '& label': { color: 'white' },
                          '& label.Mui-focused': { color: '#FFCC00' },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: 'white' },
                            '&:hover fieldset': { borderColor: '#FFCC00' },
                            '&.Mui-focused fieldset': {
                              borderColor: '#FFCC00',
                            },
                          },
                        }}
                      />
                    </Grid>
                    {/* Style Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6" sx={{ color: 'white', mt: 3 }}>
                        Style Your LifeCode
                      </Typography>
                    </Grid>
                    <Grid container spacing={3} sx={{ mt: 2 }}>
                      <Grid container item xs={6}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: 'white' }}
                          >
                            Logo For Your LifeCode
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <TextField
                            label="URL for LifeCode Logo"
                            value={image}
                            onChange={(e) => setImage(e.target.value)}
                            fullWidth
                            size="medium"
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              mt: 3,
                              bgcolor: 'rgba(255, 255, 255, 0.2)', // Match Name Your LifeCode background
                              borderRadius: '5px',
                              marginLeft: '20px',
                              input: { color: 'white' }, // White text
                              '& label': { color: 'white' }, // White label text
                              '& label.Mui-focused': { color: '#FFCC00' }, // Yellow focus effect
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: 'white' }, // White border
                                '&:hover fieldset': { borderColor: '#FFCC00' }, // Yellow hover effect
                                '&.Mui-focused fieldset': {
                                  borderColor: '#FFCC00',
                                }, // Yellow focus border
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{ color: 'white', textAlign: 'center' }}
                          >
                            -- OR --
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {/* Image Upload Section */}
                          <Box
                            sx={{
                              mt: 2,
                              p: 3,
                              border: '2px dashed #FFCC00',
                              borderRadius: '10px',
                              cursor: 'pointer',
                              backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              },
                            }}
                            component="label"
                          >
                            <input
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={handleFileUpload}
                            />
                            <CloudUploadIcon
                              sx={{ fontSize: '3rem', color: '#FFCC00' }}
                            />
                            <Typography sx={{ color: 'white', mt: 1 }}>
                              Click or Drag & Drop an Image
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item xs={6}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: 'white', mb: 1 }}
                          >
                            Select QR Code Style
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <RadioGroup
                            row
                            sx={{ justifyContent: 'center' }}
                            value={qrStyle}
                            onChange={(e) => setQrStyle(e.target.value)}
                          >
                            <FormControlLabel
                              value="dots"
                              control={<Radio sx={{ color: 'white' }} />}
                              label="Dots"
                            />
                            <FormControlLabel
                              value="squares"
                              control={<Radio />}
                              label="Squares"
                              sx={{ color: 'white' }}
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: 'white', mb: 1 }}
                          >
                            Eye Design--- Radius: {eyeRadius}{' '}
                          </Typography>
                          <Slider
                            value={eyeRadius}
                            onChange={(e, newValue) => setEyeRadius(newValue)}
                            min={0}
                            max={50}
                            sx={{
                              color: '#FFCC00',
                              maxWidth: '70%',
                              '& .MuiSlider-thumb': {
                                backgroundColor: 'white',
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Box>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: 'white', mb: 1 }}
                            >
                              Choose Foreground Color
                            </Typography>
                            <ChromePicker
                              color={fgColor}
                              onChange={(color) => setFgColor(color.hex)}
                              disableAlpha
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: 'white', mb: 1 }}
                          >
                            Choose Background Color
                          </Typography>
                          <ChromePicker
                            color={bgColor}
                            onChange={(color) => setBgColor(color.hex)}
                            disableAlpha
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Preview Section */}
                  <Grid
                    container
                    item
                    lg={6}
                    md={12}
                    xs={12}
                    justifyContent={'center'}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <QRCode
                        value={shortenedUrl}
                        logoImage={image}
                        fgColor={fgColor}
                        bgColor={bgColor}
                        enableCORS
                        eyeRadius={eyeRadius}
                        size={isMobile ? 150 : 250} // Responsive QR Code Size
                        qrStyle={qrStyle}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<ArrowRightAltIcon />}
                  onClick={() =>
                    props.saveBeacon({
                      beaconId,
                      name,
                      description,
                      type,
                      image,
                      fgColor,
                      bgColor,
                      qrStyle,
                      shortenedUrl,
                      eyeRadius,
                    })
                  }
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#b71c1c',
                    color: '#fff',
                    width: '100%',
                  }}
                >
                  Save Your LifeCode To Get Started
                </Button>
              </Grid>
            </animated.div>
          )}
        </Grid>
      </Box>
    </animated.div>
  );
};

export default LifeCodeCreator;
