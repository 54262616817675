import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import LocationOn from "@material-ui/icons/LocationOn";
import TagIframe from '../ui/tagIframe.component';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { deviceDetect } from 'react-device-detect';
import axios from 'axios';
import HeaderPullDownComponent from '../ui/headerPullDown.component';
//import Carousel from "react-material-ui-carousel";
import PDF from '../ui/widgets/pdf.component';
// import { CardContent, CardMedia } from "@mui/material";
import AwesomeSlider from 'react-awesome-slider';
import { createMarkup, setUpPdfRendering } from '../utilities';
import 'react-awesome-slider/dist/styles.css';
import { Chip } from '@material-ui/core';
//import Paper from "@mui/material/Paper";
import { CardContent, CardHeader, CardMedia, Grid } from '@mui/material';
import LocationOn from '@material-ui/icons/LocationOn';
import BottomToolbar from '../ui/bottomToolBar.component';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import Parse from 'parse';
import * as R from 'ramda';

//let profileImage = '/images/noProfile.png';
const deviceInfo = deviceDetect();
const functionName = window.location.href.includes('nfctags')
  ? 'getNfcTags'
  : 'tagsByBeaconId';

let beacon;
const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  header: {
    fontWeight: 'bold',
    margin: '10px 0',
  },
  paragraph: {
    marginBottom: 20,
  },
  card: {
    width: '100%',
    wordWrap: 'break-word',
    position: 'relative',
    boxShadow: '0 28px 14px 0 rgba(0,0,0,0.5)',
    overflow: 'visible',
    borderRadius: '18px 18px 16px 16px',
    transition: '0.4s',
    marginBottom: '15px',
    backgroundColor: 'white',
    top: 12,
    marginLeft: '2%',
    color: 'black',
    '&:hover': {
      transform: 'translateY(-7px)',
      '& $shadow': {
        bottom: '-1.5rem',
      },
      '& $shadow2': {
        bottom: '-2.5rem',
      },
    },
    '&:before': {
      position: 'absolute',
      zIndex: 0,
      display: 'block',
      width: '100%',
      bottom: 70,
      height: '100%',
      borderRadius: '1.5rem',
    },
  },

  carouselHolder: {
    width: '100%',
    wordWrap: 'break-word',
    position: 'relative',
    overflow: 'visible',
    borderRadius: '18px 18px 16px 16px',
    transition: '0.4s',
    marginTop: '124px',
    backgroundColor: '#F0F4FF !important',
    color: 'black',
    '&:hover': {
      transform: 'translateY(-7px)',
      '& $shadow': {
        bottom: '-1.5rem',
      },
      '& $shadow2': {
        bottom: '-2.5rem',
      },
    },
    '&:before': {
      position: 'absolute',
      zIndex: 0,
      display: 'block',
      width: '100%',
      bottom: 70,
      height: '100%',
      borderRadius: '1.5rem',
    },
  },

  fromText: {
    fontSize: '22px',
    color: 'rgba(168,0,0)',
    fontWeight: 700,
    fontFamily: 'helvetica',
  },

  media: {
    height: 'auto',
    width: '100%',
    objectFit: 'contain',
    paddingTop: '6.25%', // 16:9
  },

  cardcontent: {
    padding: 0,
  },

  rounded: {
    borderTop: '1px solid #bbb',
    borderRadius: '5px',
  },

  messageText: {
    fontSize: '14px',
    color: 'rgba(0,0,0)',
    fontWeight: 400,
    fontFamily: 'helvetica',
    marginLeft: '22px',
  },

  iframeStyle: {
    maxHeight: '500px',
  },

  bottomLayer: {
    boxShadow: '0 1px 1px 0 rgb(0 0 0 / 10%)',
  },
  // gridList: {
  //   width: 500,
  //   height: 450,
  // },
};

const EDGE_WIDTH = 60; // px
let slider;
let tapTimer;
let isAtLeftEdge;
let isAtRightEdge;

class FilterByBeacon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      totalSlides: 0,
      currentSlide: 0,
      marginTop: '119px',
      user: null,
      existingUser: null,
    };

    if (!props.lifeTags) {
      window.Intercom('update', {
        hide_default_launcher: true,
      });

      window.dataLayer.push({
        event: `${this.props.match.params.id}_viewed`,
      });

      if (!window.location.href.includes('nfctags')) {
        const thisID = this.props.id
          ? this.props.id
          : this.props.match.params.id;

        const params = {};
        if (window.location.href.includes('push')) {
          params.pushId = thisID;
        } else {
          params.id = thisID;
        }

        params.deviceInfo = deviceInfo;

        this.checkIfFollowing(params);

        //this.props.allByBeacon(params, functionName);
        this.getBeacon();
        navigator.geolocation.getCurrentPosition(
          this.getLocationTags,
          this.getTagsOnError
        );
      }
    }
  }

  getBeacon = async () => {
    beacon = await Parse.Cloud.run('getBeacon', {
      id: this.props.match.params.id,
    });
  };

  checkIfFollowing = async (params) => {
    const config = {
      apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    };

    // initialize Firebase messaging
    const app = initializeApp(config);
    const messaging = getMessaging(app);
    let existingUser;
    // check if the user is already subscribed to push notifications

    try {
      const thisID = this.props.id ? this.props.id : this.props.match.params.id;

      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_WEB_PUSH_KEY,
      });

      if (currentToken) {
        existingUser = await Parse.Cloud.run('getUserByWebPushId', {
          webPushId: currentToken,
        });
        // const query = new Parse.Query(Parse.User);
        // query.equalTo('webPushId', currentToken);
        // existingUser = await query.first();
        if (!R.isEmpty(existingUser)) {
          this.setState({ existingUser: existingUser });
        }
      }

      params.beaconId = thisID;

      params.recipient =
        Parse.User.current()?.id || this.state.existingUser?.id;

      this.props.allByBeacon(params, functionName);
    } catch (error) {
      console.log('error getting token', error);

      error;
    }
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  handleTouchStart = (e) => {
    // Clear any previous tap timer
    clearTimeout(tapTimer);
    isAtLeftEdge = e.changedTouches[0].pageX < EDGE_WIDTH;
    isAtRightEdge = e.changedTouches[0].pageX > window.innerWidth - EDGE_WIDTH;

    // Set a timer to determine if quick tap
    tapTimer = setTimeout(() => {
      // Timer expired - treat as swipe/scroll
      tapTimer = null;
    }, 200);
  };

  // Touch handler
  handleTouchEnd = () => {
    if (tapTimer) {
      if (isAtLeftEdge) {
        // Tapped left edge
        slider.clickPrev();
      }
      if (isAtRightEdge) {
        // Tapped right edge
        slider.clickNext();
      }
    }
  };

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  componentDidUpdate() {
    setUpPdfRendering();

    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }

  setSlideNumbers = (slider) => {
    this.setState({
      totalSlides: slider.slides,
      currentSlide: slider.currentIndex + 1,
    });
  };

  getLocationTags = async (position) => {
    const params = {};
    const thisID = this.props.id ? this.props.id : this.props.match.params.id;

    params.theLocation = {
      __type: 'GeoPoint',
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    params.deviceInfo = deviceInfo;

    // if (this.state.user) {
    params.recipient = Parse.User.current()?.id || this.state.existingUser?.id;
    //}

    if (window.location.href.includes('push')) {
      params.pushId = thisID;
      //params.recipient = this.state.existingUser?.id;

      this.props.allByBeacon(params, functionName);
    } else {
      params.beaconId = thisID;
      this.props.allByBeacon(params, functionName);
    }
  };

  getTagsOnError = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    const params = {};
    params.recipient = Parse.User.current().id || this.state.existingUser?.id;
    const thisID = this.props.id ? this.props.id : this.props.match.params.id;
    if (res.data.latitude && res.data.longitude) {
      params.theLocation = {
        __type: 'GeoPoint',
        latitude: res.data.latitude,
        longitude: res.data.longitude,
      };
      params.deviceInfo = deviceInfo;
      if (window.location.href.includes('push')) {
        params.pushId = thisID;
        this.props.allByBeacon(params, functionName);
      } else {
        params.beaconId = thisID;
        this.props.allByBeacon(params, functionName);
      }
    } else {
      if (window.location.href.includes('push')) {
        params.pushId = thisID;
        this.props.allByBeacon(params, functionName);
      } else {
        params.beaconId = thisID;
        this.props.allByBeacon(params, functionName);
      }
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    if (Parse.User.current()) {
      this.setUser(Parse.User.current().id);
    }

    if (!this.props.lifeTags) {
      window.dataLayer.push({
        event: 'event',
        eventProps: {
          category: 'LifeCode Scanned',
          action: `LifeCode ${this.props.match.params.id} Scanned`,
          label: this.props.match.params.id,
          value: this.props.match.params.id,
        },
      });
    }

    setUpPdfRendering();
  }

  render() {
    const tags = this.props.lifeTags ? this.props.lifeTags : this.props.tags;
    let height = window.innerHeight * 0.95 - 119;
    let currentTag = tags[this.state.currentSlide - 1];
    let showHeader = currentTag?.showHeader;
    const awssldBullets = document.getElementsByClassName('awssld__bullets');

    const carouselMargin = showHeader ? '88px' : '0px';

    if (showHeader && awssldBullets && awssldBullets.length > 0) {
      window.document.getElementsByClassName(
        'awssld__bullets'
      )[0].style.marginTop = '17px';
    } else if (awssldBullets && awssldBullets.length > 0) {
      window.document.getElementsByClassName(
        'awssld__bullets'
      )[0].style.marginTop = '54px';
    }
    const { classes } = this.props;

    return (
      <Grid
        id="tag-filter-by-beacon"
        container
        direction={'column'}
        justifyContent={'center'}
      >
        {tags[0] && showHeader && (
          <Grid item xs={12}>
            <HeaderPullDownComponent tag={tags[0]}></HeaderPullDownComponent>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          container
          direction={'row'}
          sx={{ height: '100%', minHeight: height, marginBottom: '10px' }}
          justifyContent={'center'}
          onTouchStart={this.handleTouchStart}
          onTouchEnd={this.handleTouchEnd}
        >
          <AwesomeSlider
            className={classes.carouselHolder}
            ref={(sliderRef) => {
              slider = sliderRef;
            }}
            style={{
              marginTop: carouselMargin,
            }}
            fillParent={true}
            infinite={false}
            mobileTouch={false}
            bullets={true}
            threshold={1100070}
            onFirstMount={(slider) => {
              this.setSlideNumbers(slider);
            }}
            onTransitionEnd={(slider) => {
              this.setSlideNumbers(slider);
            }}
          >
            {tags.map((tag, key) => (
              <div
                key={key}
                style={{
                  zIndex: 2,
                  overflowY: 'scroll',
                  height: '100%',
                  backgroundColor: 'white',
                }}
              >
                {tag.tags && tag.tags.length > 0 && (
                  <Grid container justifyContent={'center'} spacing={2}>
                    {tag.tags?.map((chipTag, key) => (
                      <Grid item xs={'auto'} key={key}>
                        <Chip
                          label={chipTag}
                          value={chipTag}
                          color={'primary'}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}

                {tag.address && (
                  <Grid item xs={12}>
                    <MuiThemeProvider>
                      <CardHeader
                        subheader={
                          <Grid item xs={12}>
                            <Grid
                              container
                              className={classes.messageText}
                              direction="row"
                              alignItems="center"
                            >
                              <Grid item>
                                <LocationOn />
                              </Grid>
                              <Grid item>{tag.address}</Grid>
                            </Grid>
                          </Grid>
                        }
                      ></CardHeader>
                    </MuiThemeProvider>
                  </Grid>
                )}

                {tag.html && (
                  <Grid item xs={12} sx={{ height: '100%' }}>
                    {/* <CardContent sx={{borderRadius: '8px'}}> */}

                    <div
                      style={{
                        width: '100vw',
                        height: '100%',
                        paddingLeft: '7px',
                        paddingRight: '7px',
                      }}
                      dangerouslySetInnerHTML={createMarkup(tag.html)}
                    ></div>
                    {/* </CardContent> */}
                  </Grid>
                )}

                {tag.markUp && !tag.html && (
                  <Grid item xs={12}>
                    <CardContent sx={{ borderRadius: '8px' }}>
                      <ReactMarkdown plugins={[gfm]}>
                        {tag.markUp}
                      </ReactMarkdown>
                    </CardContent>
                  </Grid>
                )}

                {!tag.markUp && !tag.html && tag.message && (
                  <Grid item xs={12}>
                    <CardContent className={classes.messageText}>
                      {tag.message}
                    </CardContent>
                  </Grid>
                )}

                {tag.imgUrl &&
                  !tag.isVideo &&
                  tag.type != 'pdf' &&
                  !tag.html && (
                    <CardMedia
                      className={classes.media}
                      sx={{ borderRadius: '8px' }}
                      src={tag.imgUrl}
                      title={tag.message}
                      component="img"
                    />
                  )}
                {tag.hasWeb && !tag.imgUrl && !tag.isVideo && !tag.html && (
                  <Grid item xs={12}>
                    <CardContent sx={{ borderRadius: '8px' }}>
                      <TagIframe
                        height={height - 75}
                        url={tag.hasWeb}
                        metaData={tag.metaData}
                        iframeAble={tag.iframeAble}
                      />
                    </CardContent>
                  </Grid>
                )}
                {tag.type == 'pdf' && (
                  <Grid item xs={12}>
                    <CardContent>
                      <PDF advanceButtons={true} file={tag.imgUrl} />
                    </CardContent>
                  </Grid>
                )}
                {/* </Paper> */}
              </div>
            ))}
          </AwesomeSlider>
        </Grid>
        {tags[0] && (
          <Grid item xs={12}>
            <BottomToolbar
              userId={tags[0].parent}
              from={tags[0].from}
              callBack={this.setUser}
              existingUser={this.state.existingUser}
              beacon={beacon}
            ></BottomToolbar>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(FilterByBeacon);
