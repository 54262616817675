import React, { Component } from 'react';
import {
  Button,
  Modal,
  Grid,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { CreateQr as CreateQrCode } from './beacon.container';
import { ShowQr } from './beacon.container';
import { createMuiTheme } from '@mui/material';
import { Flags } from 'react-feature-flags';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

let theme = createMuiTheme();
const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  margin: {
    margin: theme.spacing.unit * 2,
  },

  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },

  card: {
    margin: '10px auto',
    width: '95%',
  },
  delete: {
    background: '#c22d08',
    border: 0,
    color: 'white',
    padding: '10px',
    boxShadow: '0 3px 5px 2px rgba(235, 64, 52 .3)',
    borderRadius: 5,
  },
  modal: {
    display: 'flex',
    borderRadius: 40,
    padding: '10px',
    backgroundColor: 'white',
  },
  typography: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 14,
    body1: {
      fontWeight: 400,
      fontFamily: 'Helvetica',
    },
  },
  datetext: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 12,
    body1: {
      fontWeight: 400,
      fontFamily: 'Helvetica',
    },
  },
  tableHeading: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 16,
    color: 'black',
    body1: {
      fontWeight: 500,
      fontFamily: 'Helvetica',
    },
  },

  myButton: {
    backgroundColor: '#A80000',
    color: '#FFFFFF',
    borderRadius: 29.5,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: 'helvetica',
    marginLeft: '22px',
    marginBottom: 18,
  },

  button: {
    '&:hover': {
      background: 'transparent',
    },
  },
};

class QrIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      filteredQRCodes: [],
      page: 1,
      limit: 20,
    };
    this.props.allLifeCodes({ limit: this.state.limit, page: this.state.page });
  }

  componentDidMount() {}

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  createQr() {
    this.setState({ open: true });
  }

  handlePageChange = (newPage) => {
    this.setState({ page: newPage }, () =>
      this.props.allLifeCodes({
        limit: this.state.limit,
        page: this.state.page,
      })
    );
  };

  handleSearch = () => {
    this.props.allLifeCodes({
      limit: this.state.limit,
      page: this.state.page,
      filter: this.state.searchTerm,
    });
  };

  handleClear = () => {
    this.setState({ searchTerm: '' });
    this.props.allLifeCodes({
      limit: this.state.limit,
      page: this.state.page,
    });
  };

  render() {
    const { classes, history } = this.props;

    const qrCodes = this.props.beacons;

    return (
      <Grid container direction="column">
        <Box mb={2}>
          <Box mb={2}>
            <TextField
              label="Search QR Codes"
              variant="outlined"
              value={this.state.searchTerm}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleSearch}>
                      <SearchIcon />
                    </IconButton>
                    <IconButton onClick={this.handleClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                this.setState({ searchTerm: e.target.value });
              }}
            />
          </Box>
        </Box>
        <Box mb={5}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handlePageChange(this.state.page - 1)}
                disabled={this.state.page === 1}
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handlePageChange(this.state.page + 1)}
                disabled={this.props.beacons?.length < 20}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Flags authorizedFlags={['free']}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={this.props.beacons.length >= 2}
              // className={classes.myButton}
              onClick={() => {
                this.createQr();
              }}
            >
              Create LifeCode
            </Button>
          </Grid>
        </Flags>

        <Flags authorizedFlags={['pro', 'lite', 'premium']}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              // className={classes.myButton}
              onClick={() => {
                this.createQr();
              }}
            >
              Create LifeCode
            </Button>
          </Grid>
        </Flags>
        <MuiThemeProvider>
          <Modal
            open={this.state.open}
            className={classes.Modal}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'scroll',
            }}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <CreateQrCode handleClose={this.handleClose} />
          </Modal>
        </MuiThemeProvider>

        {qrCodes.length > 0 && (
          <Grid container spacing={3}>
            {qrCodes.map(
              (item, key) =>
                item.type === 'qrcode' && (
                  <Grid item xs={4} key={key}>
                    <ShowQr
                      qrCode={item}
                      size={122}
                      direction="row"
                      justifyContent="flex-start"
                      key={key}
                      history={history}
                    />
                  </Grid>
                )
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(QrIndex);
