import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getProducts } from '../products/products.actions';
import Intercom from '@intercom/messenger-js-sdk';

//import { useSpring, animated } from '@react-spring/web';
//import { styled } from '@mui/system';

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
  Modal,
} from '@mui/material';
import { Get as Show } from '../products/products.container';
import Paper from '@mui/material/Paper';
import { loadStripe } from '@stripe/stripe-js';
import { fetchCheckoutSession } from '../stripe/stripe.actions';
import Box from '@mui/material/Box';
import { Parse } from 'parse';
import * as R from 'ramda';
import LifeCodeCreator from '../ui/lifeCodeCreator.component';

import { createBeacon } from '../beacons/beacon.actions';

// const style = {
//   position: 'fixed',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 600,
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
//   borderRadius: '10px',
// };

const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Mobile friendly width
  maxWidth: '600px',
  bgcolor: 'rgba(26, 30, 43, 0.85)', // Dark transparent background
  boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
  p: 4,
  borderRadius: '10px',
  backdropFilter: 'blur(12px)', // Adds a smooth blur effect
};

export function RegSpecial() {
  const dispatch = useDispatch();
  let products = useSelector((state) => state.products.data);
  let stripeSession = useSelector((state) => state.stripe.data);
  let [accountPlan, setAccountPlan] = useState(null);
  let [activeProducts, setActiveProducts] = useState(null);
  const [beacon, setBeacon] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const stripePromise = loadStripe(
    process.env[`REACT_APP_STRIPE_${process.env.REACT_APP_STAGE}_KEY`]
  );
  const [userParams, setUserParams] = useState(null);
  const [isYearly, setIsYearly] = useState(false);
  const [passwordError, setPasswordError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [phoneError, setPhoneError] = useState(true);

  const yearlyLabel = isYearly
    ? 'Yearly(Yay!!! you Get Two Months Free)'
    : 'Yearly(Toggle to get 2 Months Free)';
  const backgroundColors = ['#E6E2F7', '#F9F0E1', '#BFE2EB'];
  const requiredFields = [
    'name',
    'username',
    'email',
    'company',
    'phoneNumber',
  ];

  Intercom({
    app_id: 'jz4lhp2p',
  });

  const accountPlanChanged = (plan) => {
    let newParams = {};
    if (userParams) {
      newParams = { ...userParams };
    }
    newParams.accountPlan = plan;
    setUserParams(newParams);
    setAccountPlan(plan);
    setPlanModalOpen(false);
    setModalOpen(true);
  };

  // useEffect(() => {
  //   // Create the script element
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.async = true;

  //   // Define the script content
  //   script.innerHTML = `
  //     !function () {
  //       var reb2b = window.reb2b = window.reb2b || [];
  //       if (reb2b.invoked) return;
  //       reb2b.invoked = true;
  //       reb2b.methods = ["identify", "collect"];
  //       reb2b.factory = function (method) {
  //         return function () {
  //           var args = Array.prototype.slice.call(arguments);
  //           args.unshift(method);
  //           reb2b.push(args);
  //           return reb2b;
  //         };
  //       };
  //       for (var i = 0; i < reb2b.methods.length; i++) {
  //         var key = reb2b.methods[i];
  //         reb2b[key] = reb2b.factory(key);
  //       }
  //       reb2b.load = function (key) {
  //         var script = document.createElement("script");
  //         script.type = "text/javascript";
  //         script.async = true;
  //         script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/XOE9GH2MQ3OM.js.gz";
  //         var first = document.getElementsByTagName("script")[0];
  //         first.parentNode.insertBefore(script, first);
  //       };
  //       reb2b.SNIPPET_VERSION = "1.0.1";
  //       reb2b.load("XOE9GH2MQ3OM");
  //     }();
  //   `;

  //   // Append the script to the document
  //   document.body.appendChild(script);

  //   // Cleanup to remove the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    async () => {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'jz4lhp2p',
      });
    };
  }, []);

  useEffect(async () => {
    if (stripeSession) {
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: stripeSession.id });
    }
  }, [stripeSession]);

  const fieldChangeHandler = (e, field) => {
    setHasError(false);
    let newParams = {};
    if (userParams) {
      newParams = { ...userParams };
    }
    newParams[field] = e.target.value;
    setUserParams(newParams);
    if (
      passwordError ||
      emailError ||
      phoneError ||
      !hasRequiredFields(newParams)
    ) {
      setDisabled(true);
    } else {
      setDisabled();
    }
  };

  const saveBeacon = (beac) => {
    console.log('iam saving the beacon ', beac);
    setBeacon(beac);
    setPlanModalOpen(true);
  };

  const hasRequiredFields = (obj) => {
    if (!obj) {
      return false;
    }
    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (!(field in obj) || obj[field] === '' || R.empty(obj[field])) {
        return false;
      }
    }
    return true;
  };

  const handlePasswordError = () => {
    if (userParams.password !== userParams.passwordConfirmation) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleClick = async () => {
    const user = new Parse.User();
    const newParams = Object.assign({}, userParams);
    newParams.status = 'trialing';
    delete newParams.passwordConfirmation;
    delete newParams.apiKey;
    newParams.accountPlan = accountPlan;
    const isFreePlan =
      accountPlan === 'price_1Qf1d5G7JJ7XmqTHu7j8GQv0' ||
      accountPlan === 'price_1Qf1MbG7JJ7XmqTHrJw4F8aE'
        ? true
        : false;

    if (isFreePlan) {
      await user.signUp(newParams, {});
    } else {
      await user.save(newParams, {});
    }

    try {
      if (user && !isFreePlan) {
        newParams.trial = true;
        dispatch(fetchCheckoutSession(newParams));
      }

      if (beacon) {
        let updatedBeacon = {};
        updatedBeacon = { ...beacon };
        updatedBeacon.parent = user;
        updatedBeacon.followButtonText = 'Follow';
        console.log('this is the updated Beacon ', updatedBeacon);
        dispatch(createBeacon({ query: updatedBeacon }));
      }

      if (isFreePlan) {
        window.location.href = '/qrcode';
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(error.message);
    }
  };

  const validateEmail = (email) => {
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhone = (phone) => {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phone);
  };
  useEffect(() => {
    if (userParams && userParams.email) {
      validateEmail(userParams.email)
        ? setEmailError(false)
        : setEmailError(true);
    }

    if (userParams && userParams.phoneNumber) {
      validatePhone(userParams.phoneNumber)
        ? setPhoneError(false)
        : setPhoneError(true);
    }
  }, [userParams]);

  useEffect(() => {}, [accountPlan]);

  useEffect(() => {
    if (!products) {
      return;
    }
    const activeProducts = products.filter((product) => product.active);
    for (let i = 0; i < activeProducts.length; i++) {
      const product = activeProducts[i];
      product.color = backgroundColors[i];
    }

    activeProducts.sort(
      (a, b) =>
        a.prices?.find((price) => price.id === a.default_price).unit_amount -
        b.prices?.find((price) => price.id === b.default_price).unit_amount
    );

    setActiveProducts(activeProducts);
  }, [products]);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={modalStyle}>
          <Card sx={{ background: 'transparent', boxShadow: 'none' }}>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  color: '#FFCC00',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  mb: 2,
                }}
              >
                Register Your Account
              </Typography>

              {/* Name Field */}
              <TextField
                required
                id="name"
                label="Name"
                fullWidth
                margin="normal"
                onChange={(e) => fieldChangeHandler(e, 'name')}
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '5px',
                  input: { color: 'white' },
                  '& label': { color: 'white' },
                  '& label.Mui-focused': { color: '#FFCC00' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: '#FFCC00' },
                    '&.Mui-focused fieldset': { borderColor: '#FFCC00' },
                  },
                }}
              />

              {/* Username Field */}
              <TextField
                required
                id="username"
                label="Username"
                fullWidth
                margin="normal"
                onChange={(e) => fieldChangeHandler(e, 'username')}
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '5px',
                  input: { color: 'white' },
                  '& label': { color: 'white' },
                  '& label.Mui-focused': { color: '#FFCC00' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: '#FFCC00' },
                    '&.Mui-focused fieldset': { borderColor: '#FFCC00' },
                  },
                }}
              />

              {/* Email & Phone with Error Messages */}
              <FormHelperText
                error={emailError}
                sx={{
                  visibility: emailError ? 'visible' : 'hidden',
                  color: 'red',
                }}
              >
                The email you entered is invalid
              </FormHelperText>
              <TextField
                required
                id="email"
                label="Email"
                fullWidth
                margin="normal"
                type="email"
                onChange={(e) => fieldChangeHandler(e, 'email')}
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '5px',
                  input: { color: 'white' },
                  '& label': { color: 'white' },
                  '& label.Mui-focused': { color: '#FFCC00' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: '#FFCC00' },
                    '&.Mui-focused fieldset': { borderColor: '#FFCC00' },
                  },
                }}
              />

              <FormHelperText
                error={phoneError}
                sx={{
                  visibility: phoneError ? 'visible' : 'hidden',
                  color: 'red',
                }}
              >
                The phone number you entered is invalid
              </FormHelperText>
              <TextField
                required
                id="phone"
                label="Phone Number"
                fullWidth
                margin="normal"
                type="tel"
                onChange={(e) => fieldChangeHandler(e, 'phoneNumber')}
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '5px',
                  input: { color: 'white' },
                  '& label': { color: 'white' },
                  '& label.Mui-focused': { color: '#FFCC00' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: '#FFCC00' },
                    '&.Mui-focused fieldset': { borderColor: '#FFCC00' },
                  },
                }}
              />

              {/* Password Fields */}
              <TextField
                required
                id="password"
                label="Password"
                fullWidth
                defaultValue=""
                type="password"
                margin="normal"
                error={passwordError}
                name="password"
                onBlur={handlePasswordError}
                onChange={(e) => fieldChangeHandler(e, 'password')}
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '5px',
                  input: { color: 'white' },
                  '& label': { color: 'white' },
                  '& label.Mui-focused': { color: '#FFCC00' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: '#FFCC00' },
                    '&.Mui-focused fieldset': { borderColor: '#FFCC00' },
                  },
                }}
              />
              <TextField
                required
                id="password"
                label="Password Confirmation: "
                type="password"
                fullWidth
                defaultValue=""
                margin="normal"
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '5px',
                  input: { color: 'white' },
                  '& label': { color: 'white' },
                  '& label.Mui-focused': { color: '#FFCC00' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: '#FFCC00' },
                    '&.Mui-focused fieldset': { borderColor: '#FFCC00' },
                  },
                }}
                onChange={(change) =>
                  fieldChangeHandler(change, 'passwordConfirmation')
                }
                error={passwordError}
                name="passwordConfirmation"
                onBlur={handlePasswordError}
              />
              <TextField
                required
                id="company"
                label="Company: "
                fullWidth
                defaultValue=""
                margin="normal"
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '5px',
                  input: { color: 'white' },
                  '& label': { color: 'white' },
                  '& label.Mui-focused': { color: '#FFCC00' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: '#FFCC00' },
                    '&.Mui-focused fieldset': { borderColor: '#FFCC00' },
                  },
                }}
                onChange={(change) => fieldChangeHandler(change, 'company')}
              />

              {/* Submit Button */}
              <Button
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: '#FFCC00',
                  color: '#fff',
                  mt: 3,
                  px: 3,
                  py: 1,
                  fontSize: '16px',
                  '&:hover': { bgcolor: '#ffdb4d' },
                }}
                onClick={handleClick}
                disabled={disabled}
              >
                Submit
              </Button>
            </CardContent>

            {/* Error Message */}
            <CardContent>
              <FormHelperText
                error={hasError}
                sx={{
                  visibility: hasError ? 'visible' : 'hidden',
                  color: 'red',
                }}
              >
                {errorMessage}
              </FormHelperText>
            </CardContent>
          </Card>
        </Box>
      </Modal>
      <Modal
        open={planModalOpen}
        onClose={() => setPlanModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{
          overflowY: 'auto', // Ensures inner content scrolls if needed
        }}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Paper
            sx={{
              width: '80%',
              padding: '32px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid item justifySelf={'flex-start'} xs={6}>
              <Typography sx={{ margin: '12px' }} variant={'h4'}>
                Get Started by choosing a plan
              </Typography>
            </Grid>
            <FormControlLabel
              control={
                <Switch
                  checked={isYearly}
                  onChange={() => setIsYearly(!isYearly)}
                />
              }
              label={yearlyLabel}
            />
          </Paper>
          <Grid item xs={12}>
            {activeProducts && activeProducts.length > 0 && (
              <Grid
                container
                spacing={4}
                justifyContent={'center'}
                sx={{
                  mt: 3,
                  px: { xs: 2, md: 5 },
                  overflowY: 'auto', // Ensures inner content scrolls if needed
                  maxHeight: '90vh', // Keeps the height flexible
                }}
              >
                {activeProducts.map(
                  (product, key) =>
                    product.active && (
                      <Grid
                        item
                        xs={12} // Full width on mobile
                        sm={6} // Two per row on tablets
                        md={4}
                        lg={3} // Three per row on desktop
                        key={key}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {' '}
                        <Show
                          backgroundColor={
                            backgroundColors[key % backgroundColors.length]
                          }
                          setPlan={accountPlanChanged}
                          isYearly={isYearly}
                          product={product}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
      <Paper sx={{ padding: '32px' }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <LifeCodeCreator saveBeacon={saveBeacon} />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          padding: '32px',
          marginTop: '32px',
          marginLeft: '60px',
          marginRight: '60px',
          backgroundColor: '#fafafa',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Use Cases for LifeTagger LifeCodes
        </Typography>
        <Grid
          container
          spacing={4}
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Card
              onClick={() => (window.location.href = '/conferenceevents')}
              sx={{
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                borderRadius: '12px',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': { transform: 'scale(1.05)' },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardMedia
                component="img"
                image="/images/churchcalendar.jpg"
                alt="Conference & Events"
              />
              <CardContent sx={{ padding: '24px', flexGrow: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  Conference & Events
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Enhance attendee engagement, provide dynamic schedules, and
                  offer interactive session information.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              onClick={() => (window.location.href = '/churchengagement')}
              sx={{
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                borderRadius: '12px',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': { transform: 'scale(1.05)' },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardMedia
                component="img"
                image="/images/bible.jpg"
                alt="Churches"
              />
              <CardContent sx={{ padding: '24px', flexGrow: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  Churches
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Streamline event registrations, share daily prayers, and
                  provide easy access to sermons and church resources.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Cities */}
          <Grid item xs={12} sm={6} md={2}>
            <Card
              onClick={() => (window.location.href = '/cities')}
              sx={{
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                borderRadius: '12px',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': { transform: 'scale(1.05)' },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardMedia
                component="img"
                image="/images/city.jpg"
                alt="Cities"
              />
              <CardContent sx={{ padding: '24px', flexGrow: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  Cities
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Offer citizens quick access to city services, event calendars,
                  and important public announcements.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Card
              onClick={() => (window.location.href = '/retail')}
              sx={{
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                borderRadius: '12px',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': { transform: 'scale(1.05)' },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardMedia
                component="img"
                image="/images/retail.jpg"
                alt="Retail"
              />
              <CardContent sx={{ padding: '24px', flexGrow: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  Retail
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Boost customer interaction with product details, special
                  offers, and loyalty programs.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Card
              onClick={() => (window.location.href = '/restaurants')}
              sx={{
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                borderRadius: '12px',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': { transform: 'scale(1.05)' },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardMedia
                component="img"
                image="/images/restaurant.jpg"
                alt="Restaurants"
              />
              <CardContent sx={{ padding: '24px', flexGrow: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  Restaurants
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Simplify menu access, provide daily specials, and gather
                  customer feedback efficiently.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
