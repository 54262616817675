import { connect } from 'react-redux';
import {
  fetchTags,
  deleteTag,
  createTag,
  updateTag,
  showTag,
} from './tag.actions';
import { fetchBeacons } from '../beacons/beacon.actions';
import { fetchChannels } from '../channels/channel.actions';
import { fetchMedia } from '../media/media.actions';
import { fetchCheckoutSession } from '../stripe/stripe.actions.js';

import {
  fetchConnector,
  fetchConnectors,
  fetchConnectorAction,
} from '../connectors/connector.actions';
import Index from './tag.index';
import New from './tag.new';
import Edit from './tag.edit';
import Show from './tag.show';
import Remove from './tag.remove';
import TagByBeacon from './tag.filterByBeacon';
import { setNotification } from '../shared/actions/notification.actions';
import { fetchUserConnectors } from '../userconnectors/userconnector.actions';
import { fetchSubscribers } from '../subscribers/subscriber.actions';

const mapStateToProps = (state) => ({
  tags: state.tag.data,
  location: state.history,
  channels: state.channel.data,
  beacons: state.beacon.data,
  medias: state.media.data,
  stripe: state.stripe.data,
  connectors: state.connector.data,
  userConnectors: state.userConnector.data,
  followers: state.subscriber.data,
});

const mapDispatchToProps = (dispatch) => ({
  all: (payload) => dispatch(fetchTags(payload, 'getTag')),

  allChannels: (query) => dispatch(fetchChannels({ query })),

  allByBeacon: (payload) => dispatch(fetchTags(payload, 'tagsByBeaconId')),
  fetchBeacons: () => dispatch(fetchBeacons()),

  fetchMedia: () => dispatch(fetchMedia()),
  fetchFollowers: () => dispatch(fetchSubscribers()),

  fetchConnectors: () => dispatch(fetchConnectors()),
  fetchConnector: (id) => dispatch(fetchConnector(id)),

  fetchUserConnectors: () => dispatch(fetchUserConnectors()),

  setNotification: (query) => {
    //console.log("this is the notification query", query);
    dispatch(setNotification(query));
  },

  deleteTag: (query) => {
    //console.log('query when deleting ', query);
    //query.index = ownProps.index;
    dispatch(deleteTag({ id: query }));
  },

  createTag: (query) => {
    //query.index = ownProps.index;
    dispatch(createTag({ query }));
  },

  updateTag: (query) => {
    //query.index = ownProps.index;
    dispatch(updateTag({ query }));
  },

  show: (query) => dispatch(showTag({ query })),
  fetchCheckoutSession: (query) => dispatch(fetchCheckoutSession(query)),

  fetchConnectorAction: ({ path, params, method }) => {
    dispatch(fetchConnectorAction({ path, params, method }));
  },
});

const tagsConnector = connect(mapStateToProps, mapDispatchToProps);

export const All = tagsConnector(Index);
export const Create = tagsConnector(New);
export const Update = tagsConnector(Edit);
export const Get = tagsConnector(Show);
export const Delete = tagsConnector(Remove);
export const AllByBeacon = tagsConnector(TagByBeacon);
