import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Parse } from 'parse';
import MultipleSelect from '../ui/multiSelect.component';
import SearchMap from '../ui/maps.component';
import { Grid, Typography, Button } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
//import Upload from "../ui/upload.component";
import { Get as Show } from './tag.container';
import uuid from 'react-uuid';
import * as R from 'ramda';
import 'react-datepicker/dist/react-datepicker.css';
import { grey } from '@material-ui/core/colors';
//import { Flags } from 'react-feature-flags';
import '@fontsource/heebo';
import '@fontsource/roboto';
import AddIcon from '@material-ui/icons/Add';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Fab,
  Modal,
} from '@mui/material';
import { ProductsIndex as Products } from '../products/products.index';
import {
  AccessTimeOutlined,
  CheckCircleOutline,
  CancelRounded,
  LocationOnOutlined,
  PermMediaOutlined,
  RadioButtonUnchecked,
  RadioButtonChecked,
  Add,
} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import './tag.css';
import IPhoneComponent from '../ui/iPhone.component';
import TagPreview from '../ui/tagPreview.component';
import '@fontsource/inter';
import { All as UCIndex } from '../userconnectors/userconnector.container';
import { All as ConnectorIndex } from '../connectors/connector.container';
import { withStyles } from '@material-ui/styles';
import EnhancedTable from '../ui/ltTable.component';
import { EditorComponent } from '../ui/lifetaggerTinyMceEditor.component';
import { FormControlLabel, Switch } from '@mui/material';
import { DatePickerComponent } from '../ui/datePicker.component';
import { getOrdinalSuffix } from '../utilities';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Flags } from 'react-feature-flags';
//import IntegrationData from '../ui/integrationData.component';

//const htmlRegEx = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,64}(?::(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,25})?@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9-]{0,64}\.)+(?:(?:aero|app|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?::\d{1,5})?)(\/(?:(?:[a-zA-Z0-9;/?:@&=#~\-.+!*'(),_])|(?:%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

let theme = createMuiTheme({
  overrides: {
    MuiStepConnector: {
      // Name of the component ⚛️ / style sheet
      line: {
        '&lineHorizontal': {
          borderColor: '#BF2420',
        },
        borderColor: '#BF2420',
      },
    },
  },
});

const styles = {
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  margin: {
    margin: theme.spacing.unit * 2,
  },

  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },

  card: {
    margin: '10px auto',
    width: '95%',
  },

  editor: {
    height: '200px',
  },

  qrTxt: {
    fontSize: '15px',
    color: 'rgba(168,0,0)',
    fontWeight: 700,
    fontFamily: 'helvetica',
    marginLeft: '22px',
  },

  iframeText: {
    textColor: 'rgba(168,0,0)',
    fontWeight: 700,
    color: 'rgba(168,0,0)',
    padding: '40px',
  },

  header: {
    fontFamily: 'Heebo',
    fontSize: '24px',
    marginLeft: '20px',
    marginBottom: '18px',
    fontWeight: 'bold',
  },

  subHeader: {
    fontFamily: 'Heebo',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '48px',
    fontWeight: 'bold',
    color: '#0F152A',
  },

  headerIcon: {
    color: '#DD0B06',
  },
  subtitleContainer: {
    marginTop: '18px',
    marginBottom: '51px',
  },

  subtitle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#424242',
  },

  subtitle2: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    color: '#424242',
    marginTop: '6px',
  },

  title: {
    fontFamily: 'Heebo',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },

  connector: {
    borderColor: '#D9E2FF',
  },

  completedConnector: {
    borderColor: '#BF2420',
  },

  stepper: { marginBottom: '42px' },

  stepIcon: {
    width: '12px',
    height: '12px',
    color: '#BF2420',
  },

  stepIconComponent: {
    width: '12px',
    height: '12px',
    color: 'white',
    borderColor: '#243058',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 'thin',
    marginBottom: '34px',
    '&.MuiStepIcon-active': {
      color: '#BF2420',
    },
    '&.MuiStepIcon-completed': {
      color: 'white',
      borderColor: '#BF2420',
      borderRadius: '50%',
      borderStyle: 'solid',
      backgroundImage: 'images/stepperCompleteLight.png',
    },
  },

  typography: {
    fontFamily: ['Roboto Condensed'].join(','),
    fontSize: 18,
    body1: {
      fontWeight: 500,
      fontFamily: 'Roboto Condensed',
    },
  },

  buttonContainer: {
    marginTop: '0px',
  },

  nextButtons: {
    backgroundColor: '#BF2420',
    color: '#fff',
    width: '124px',
  },

  backButtons: {
    backgroundColor: '#fff',
    color: '#424242',
    width: '124px',
    marginRight: '16px',
  },

  backButtonPreview: {
    backgroundColor: '#0F1F54',
    width: '124px',
    marginRight: '16px',
    color: '#fff',
  },

  submitButton: {
    backgroundColor: '#BF2420',
    width: '124px',
    marginRight: '16px',
    color: '#fff',
  },

  triggerButton: {
    backgroundColor: '#D9E2FF',
    width: '96px',
    height: '96px',
    color: '#fff',
  },

  triggerActive: {
    backgroundColor: '#DD0B06',
    width: '96px',
    height: '96px',
    color: '#fff',
  },

  triggerTextActive: {
    fontSize: '14px',
    color: '#fff',
    fontFamily: 'inter',
  },

  triggerTextInactive: {
    fontSize: '14px',
    color: '#000',
    fontFamily: 'heebo',
  },

  triggerIcon: {
    width: '24px',
    height: '24px',
    color: '#DD0B06',
  },

  triggerIconActive: {
    width: '24px',
    height: '24px',
    color: '#fff',
  },
  triggerButtonCheck: {
    width: '16px',
    height: '16px',
    color: '#000',
  },

  triggerItemContainer: {
    marginBottom: '24px',
    marginRight: '24px',
  },

  removeButton: {
    color: '#DD0B06',
    justifyContent: 'start',
    width: '14px',
    height: '14px',
  },

  blockquote: {
    height: '350px',
    width: '100%',
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: 'helvetica',
    marginTop: '67px',
    marginBottom: '25px',

    background:
      'linear-gradient(to right, #039be5 4px, transparent 4px) 0 0%,' +
      'linear-gradient(to bottom, #039be5 4px, transparent 4px) 0% 0,' +
      'linear-gradient(to right, #039be5 4px, transparent 4px) 0 100%,' +
      'linear-gradient(to left, #039be5 4px, transparent 4px) 100% 0,' +
      'linear-gradient(to left, #039be5 4px, transparent 4px) 100% 100%,' +
      'linear-gradient(to bottom, #039be5 4px, transparent 4px) 100% 0,' +
      'linear-gradient(to top, #039be5 4px, transparent 4px) 100% 100%,' +
      'linear-gradient(to top, #039be5 4px, transparent 4px) 0 100%',
    margin: '1.5em 10px',
    padding: '0.5em 15px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
    '&:p': {
      display: 'inline',
    },
  },

  textField: {
    background: '#F7F9FF',
  },

  shouldDisplay: {},

  shouldNotDisplay: {
    display: 'none',
  },

  addIcon: {
    width: '12px',
    height: '12px',
    color: '#424242',
  },

  outlinedChip: {
    borderColor: '#616161',
    fontWeight: 300,
    fontSize: '12px',
  },

  selectedText: {
    fontFamily: 'Heebo',
    fontSize: '14px',
    color: '#4B4B4B',
    fontWeight: 700,
  },

  selectedChip: {
    backgroundColor: '#B70000',
    fontWeight: 400,
    fontFamily: 'Heebo',
    color: '#FFFFFF',
  },

  svgIcon: {
    height: '24px',
    width: '24px',
    textAlign: 'center',
  },

  dateTimePicker: {
    width: '50%',
  },
};

class New extends Component {
  static propTypes = {
    setMessageText: PropTypes.func,
  };
  handleConnectorClick = (id) => {
    this.props.fetchConnector(id);
  };

  handleConnectorActionClick = async (action, id) => {
    this.props.fetchConnectorAction(action);
    action.id = id;
    this.setState({ activeConnectorAction: action });
  };

  constructor(props) {
    super(props);

    // const stripePromise = loadStripe(
    //   process.env[`REACT_APP_STRIPE_${process.env.REACT_APP_STAGE}_KEY`]
    // );
    let userName, userCompanyName;
    if (Parse.User.current()) {
      let parseUser = Parse.User.current();
      userName = parseUser.get('name');
      userCompanyName = parseUser.get('company');
      userCompanyName = userCompanyName
        ? userCompanyName
        : userName + "'s Company";
    }

    this.props.allChannels();
    this.props.fetchBeacons();
    this.props.fetchMedia();
    this.props.fetchConnectors();
    this.props.fetchUserConnectors();
    this.props.fetchFollowers();
    const searchParams = new URLSearchParams(window.location.search);

    // const theDate = new Date();
    // const endDate = theDate.setDate(theDate.getDate() + 1);
    // console.log('endDate', endDate);

    this.state = {
      channels: [],
      beacons: searchParams.get('lifecode')
        ? [searchParams.get('lifecode')]
        : [],
      latitude: '',
      longitude: '',
      message: '',
      image: null,
      address: '',
      media: [],
      expirationString: null,
      fileName: '',
      phoneNumbers: [],
      recipients: [],
      qrcode: searchParams.get('lifecode') ? true : false,
      from: userCompanyName,
      html: ' ',
      iframeAble: true,
      activeStep: 0,
      activeTrigger: searchParams.get('lifecode') ? [1] : [],
      startDate: null,
      endDate: null,
      recurrence: 'none',
      currentActiveTrigger: searchParams.get('lifecode') ? [1] : null,
      activeConnectorAction: null,
      type: null,
      showHeader: true,
      pushNotification: '',
      preview: false,
      lifeTags: [],
      modalOpen: false,
    };

    this.baseState = {
      send: false,
      channels: [],
      beacons: [],
      latitude: '',
      longitude: '',
      message: '',
      image: null,
      address: '',
      media: [],
      recipients: [],
      fileName: '',
      expirationString: null,
      from: userCompanyName,
      phoneNumbers: [],
      markUp: null,
      html: ' ',
      iframeAble: true,
      activeStep: 0,
      activeTrigger: [],
      startDate: null,
      endDate: null,
      recurrence: 'none',
      currentActiveTrigger: null,
      activeConnectorAction: null,
      type: null,
      showHeader: true,
      pushNotification: '',
      preview: false,
      lifeTags: [],
    };
  }

  setAddressText = (event) => {
    this.setState({
      address: event.address,
      latitude: event.latitude,
      longitude: event.longitude,
    });
  };

  onShowHeaderClick = async () => {
    this.setState({ showHeader: !this.state.showHeader });
  };

  setMessageText = async (event) => {
    // Create temp div
    let temp = document.createElement('div');

    // Set div's innerHTML to editor content
    temp.innerHTML = event.html;

    // Get content container div from temp
    let contentContainer = temp.querySelector('.content-container');
    let hasColorContainer = temp.querySelector('.color-container');

    if (hasColorContainer && !contentContainer) {
      event.html = `<div class="color-container" style="background-color: ${hasColorContainer.style.backgroundColor};"><div class="content-container">${event.html}</div></div>`;
    }

    if (!contentContainer && !hasColorContainer) {
      event.html = `<div class="content-container"">${event.html}</div>`;
    }

    this.setState({ message: event.text, html: event.html });
  };

  setPhoneNumber = (event) => {
    const pluckedPhones = R.pluck('phone', event);
    const phones = [];
    for (let i = 0; i < pluckedPhones.length; i++) {
      const phone = pluckedPhones[i];
      phones.push(...phone);
    }
    this.setState({ phoneNumbers: phones });
  };

  setPrompt = (html) => {
    this.setState({ html: html });
  };

  //what happens when we hit the submit button
  scheduleNewTag = async () => {
    let newState = {};
    for (const [key, value] of Object.entries(this.state)) {
      if (this.state[key]) {
        newState[key] = value;
      }
    }

    delete newState.sendToChannel;
    delete newState.iframeAble;
    if (newState.recurrence === 'none') {
      delete newState.recurrence;
    }

    this.setState(newState, () => {
      this.props.createTag(newState);
    });
  };

  dateCallBack = (event, type) => {
    if (type === 'start') {
      this.setState({ startDate: event });
      this.setState(
        {
          expirationString: `${event.toLocaleString() || ''} - ${
            this.state.endDate?.toLocaleString() || ''
          }`,
        },
        () => {
          this.setExpirationString();
        }
      );
    }

    if (type === 'end') {
      this.setState({ endDate: event });
      this.setState(
        {
          expirationString: `${
            this.state.startDate?.toLocaleString() || ''
          } - ${event.toLocaleString() || ''}`,
        },
        () => {
          this.setExpirationString();
        }
      );
    }

    if (type === 'recurrence') {
      const rec = event === 'none' ? null : event;
      this.setState({ recurrence: rec }, () => {
        this.setExpirationString();
      });
      //this.setState({expirationString: `${this.state.startDate?.toLocaleString() || ''} - ${this.state.endDate?.toLocaleString() || ''}, recurrence: ${rec}`});
    }
  };

  setExpirationString = () => {
    let { recurrence, startDate, endDate } = this.state;

    if (!startDate || !endDate) {
      return;
    }

    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const monthsOfYear = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    let startTimestampPretty = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(startDate);
    let endTimestampPretty = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(endDate);
    let startDayName, endDayName, startDateOrdinal, endDateOrdinal;
    let occurrenceString = '';
    switch (recurrence) {
      case 'daily':
        startDate = new Date(startDate);
        endDate = new Date(endDate);

        // If end date is on a future day, set it to the latest hour of the current day
        if (
          endDate.getDate() !== startDate.getDate() ||
          endDate.getMonth() !== startDate.getMonth() ||
          endDate.getFullYear() !== startDate.getFullYear()
        ) {
          endDate.setHours(23, 59, 59, 999);
          endDate.setDate(startDate.getDate());
          endDate.setMonth(startDate.getMonth());
          endDate.setFullYear(startDate.getFullYear());
          endTimestampPretty = new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(endDate);
          this.setState({ endDate: endDate });
        }

        occurrenceString = `Tag is active daily from ${startTimestampPretty} until ${endTimestampPretty}`;
        break;

      case 'weekly':
        startDayName = daysOfWeek[startDate.getDay()];
        endDayName = daysOfWeek[endDate.getDay()];
        occurrenceString = `Tag is active weekly on ${startDayName} at ${startTimestampPretty} until ${endDayName} at ${endTimestampPretty}`;
        break;

      case 'monthly':
        if (
          endDate.getMonth() !== startDate.getMonth() ||
          endDate.getFullYear() !== startDate.getFullYear()
        ) {
          endDate.setDate(
            new Date(
              startDate.getFullYear(),
              startDate.getMonth() + 1,
              0
            ).getDate()
          );
          endTimestampPretty = new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(endDate);
          this.setState({ endDate: endDate });
        }

        startDateOrdinal = getOrdinalSuffix(startDate.getDate());
        endDateOrdinal = getOrdinalSuffix(endDate.getDate());

        occurrenceString = `Tag is active monthly from the ${startDateOrdinal} at ${startTimestampPretty} until the ${endDateOrdinal} at ${endTimestampPretty}`;
        break;

      case 'yearly':
        occurrenceString = `Tag is active yearly from ${
          monthsOfYear[startDate.getMonth()]
        } ${startDate.getDate()} at ${startTimestampPretty} until ${
          monthsOfYear[endDate.getMonth()]
        } ${endDate.getDate()} at ${endTimestampPretty}`;
        break;

      default:
        occurrenceString = `Tag is active from ${startDate.toLocaleString()} until ${endDate.toLocaleString()}`;
    }

    this.setState({ expirationString: occurrenceString });
  };

  sendNow = async () => {
    await this.props.createTag(this.state);
  };

  handleChannelChange = (event) => {
    this.setState({ channels: event.target.value });
  };

  handlePushNotificationChange = (event) => {
    this.setState({ pushNotification: event.toLocaleString() });
  };

  setRecipients = (event) => {
    const recipients = [];

    for (let i = 0; i < event.length; i++) {
      const recipient = event[i];
      recipients.push(recipient.objectId);
    }
    this.setState({ recipients: recipients });
  };

  handleBeaconChange = (event) => {
    let beacons = [];
    let hasQr = false;

    for (let i = 0; i < event.length; i++) {
      let val = event[i];
      let beacon = this.props.beacons.find((b) => b.objectId == val.objectId);

      if (beacon?.type == 'qrcode') {
        hasQr = true;
      }
      beacons.push(beacon.objectId);
    }
    this.setState({ beacons: beacons, qrcode: hasQr });
  };

  handleMediaChange = (event) => {
    let medias = [];
    for (var i = 0; i < event.target.value.length; i++) {
      let val = event.target.value[i];
      let media = this.props.medias.find((m) => m.name == val);
      medias.push(media.acrId);
    }

    this.setState({ media: medias });
  };

  handleFileUpload = (file) => {
    if (file.length && file.length > 0) {
      this.setState({ image: file });
      for (let i = 0; i < file.length; i++) {
        switch (file[i].type) {
          case 'application/pdf':
            this.setState({ type: 'pdf' });
            break;
          case file[i].type.includes('image'):
            this.setState({ type: 'image' });
            break;
          case file[i].type.includes('video'):
            this.setState({ type: 'video' });
            break;
          default:
            break;
        }
      }
    }
  };

  deleteLifeTag = (id) => {
    const newLifeTags = this.state.lifeTags.filter((item) => item.id !== id);
    this.setState({ lifeTags: newLifeTags });
  };

  handleNext = () => {
    if (
      this.state.activeStep === 2 &&
      !R.isEmpty(this.state.message || !R.isEmpty(this.state.html))
    ) {
      this.handleAddNewMessage();
    }
    const newStep = this.state.activeStep + 1;
    this.setState({ activeStep: newStep });
  };

  removeEmptyResources = (theResource) => {
    const resources = [
      'address',
      'beacons',
      'expirationString',
      'media',
      'pushNotification',
      'phoneNumbers',
      'recipients',
    ];
    const newState = [];
    for (let i = 0; i < resources.length; i++) {
      const resource = resources[i];
      if (i != theResource) {
        if (resource == 'expirationString') {
          if (this.state.endDate != null && this.state.startDate != null) {
            newState.push(i);
          }
        } else if (!R.isEmpty(this.state[resource])) {
          newState.push(i);
        }
      }
    }
    newState.push(theResource);

    this.setState({ activeTrigger: newState });
  };

  removeResource = (resource, index) => {
    const getFromBase = {};
    if (resource === 'expirationString') {
      getFromBase['endDate'] = this.baseState['endDate'];
      getFromBase['startDate'] = this.baseState['startDate'];
      getFromBase['expirationString'] = this.baseState['expirationString'];
      getFromBase['recurrence'] = this.baseState['recurrence'];
    }

    getFromBase[resource] = this.baseState[resource];
    getFromBase.activeTrigger = this.state.activeTrigger.filter(function (i) {
      return i !== index;
    });

    getFromBase.currentActiveTrigger = null;

    this.setState(getFromBase);
  };

  handleBack = () => {
    const newStep = this.state.activeStep - 1;
    this.setState({ activeStep: newStep });
  };

  handleAddNewMessage = () => {
    let newLifeTag = Object.assign({}, this.state);
    newLifeTag.id = uuid();
    let newLifeTags = this.state.lifeTags;
    newLifeTags.push(newLifeTag);

    this.setState({ lifeTags: newLifeTags });
    this.setState({ message: '', html: '' });
    window.tinyEditorRef.current.editor.resetContent();
  };

  handleSubmit = () => {
    this.handleNext();
    this.scheduleNewTag();
  };

  handleStartDateChange = (event) => {
    this.setState({
      startDate: event,
      expirationString: `${event.toLocaleString()} - ${
        this.state.endDate?.toLocaleString() || ''
      }`,
    });
  };

  handleEndDateChange = (event) => {
    this.setState({
      endDate: event,
      expirationString: `${
        this.state.startDate?.toLocaleString() || ''
      } - ${event.toLocaleString()}`,
    });
  };

  isValidDate = () => {
    return (
      !R.includes(2, this.state.activeTrigger) ||
      (R.includes(2, this.state.activeTrigger) &&
        this.state.startDate &&
        this.state.endDate)
    );
  };

  redirectToStripePortal = async () => {
    if (Parse.User.current().get('stripeCustomerId')) {
      const params = {
        customer: Parse.User.current().get('stripeCustomerId'),
        redirectUrl: window.location.href,
      };
      const portal = await Parse.Cloud.run('createStripePortal', params);
      window.location.href = portal.url;
    } else {
      this.setState({ modalOpen: true });
    }
  };

  getStepComponents() {
    if (this.state.activeStep === 0) {
      return this.renderAudience();
    }

    if (this.state.activeStep === 1) {
      return this.renderTrigger();
    }

    if (this.state.activeStep === 2) {
      return this.renderCreateContent();
    }

    if (this.state.activeStep === 3) {
      return this.renderPreview();
    }

    if (this.state.activeStep === 4) {
      return <div />;
    }
  }

  triggerButton(trigger, index) {
    const { classes } = this.props;
    const { onClick, icon, subHeader, resource, prettyName } = trigger;

    const proFeatures = ['address', 'media', 'pushNotification'];
    console.log('this is the resource ', proFeatures.includes(resource));

    return (
      <Grid item xs={5}>
        <Grid
          container
          className={classes.triggerItemContainer}
          direction={'row'}
        >
          <Grid item xs={12}>
            <Flags
              authorizedFlags={['pro', 'beta', 'premium', 'lite']}
              renderOn={() => (
                <Button
                  className={
                    R.includes(index, this.state.activeTrigger)
                      ? classes.triggerActive
                      : classes.triggerButton
                  }
                  disabled={
                    !R.isEmpty(this.state.pushNotification) &&
                    resource != 'pushNotification'
                  }
                  onClick={() => {
                    if (!R.includes(index, this.state.activeTrigger)) {
                      this.setState(
                        {
                          activeTrigger: [...this.state.activeTrigger, index],
                          currentActiveTrigger: index,
                        },
                        () => {
                          this.removeEmptyResources(index);
                        }
                      );
                    } else {
                      this.removeResource(resource, index);
                    }
                    onClick;
                  }}
                >
                  <Grid
                    container
                    direction={'column'}
                    justify={'space-between'}
                  >
                    <Grid item xs={12} style={{ textAlign: 'end' }}>
                      {R.includes(index, this.state.activeTrigger) ? (
                        <RadioButtonChecked
                          className={classes.triggerButtonCheck}
                        />
                      ) : (
                        <RadioButtonUnchecked
                          className={classes.triggerButtonCheck}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                      {icon}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        className={
                          R.includes(index, this.state.activeTrigger)
                            ? classes.triggerTextActive
                            : classes.triggerTextInactive
                        }
                      >
                        {prettyName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              )}
              renderOff={() => (
                <div>
                  <Button
                    className={
                      R.includes(index, this.state.activeTrigger)
                        ? classes.triggerActive
                        : classes.triggerButton
                    }
                    disabled={proFeatures.includes(resource)}
                    onClick={() => {
                      if (!R.includes(index, this.state.activeTrigger)) {
                        this.setState(
                          {
                            activeTrigger: [...this.state.activeTrigger, index],
                            currentActiveTrigger: index,
                          },
                          () => {
                            this.removeEmptyResources(index);
                          }
                        );
                      } else {
                        this.removeResource(resource, index);
                      }
                      onClick;
                    }}
                  >
                    <Grid
                      container
                      direction={'column'}
                      justify={'space-between'}
                    >
                      <Grid item xs={12} style={{ textAlign: 'end' }}>
                        {R.includes(index, this.state.activeTrigger) ? (
                          <RadioButtonChecked
                            className={classes.triggerButtonCheck}
                          />
                        ) : (
                          <RadioButtonUnchecked
                            className={classes.triggerButtonCheck}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        {icon}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          className={
                            R.includes(index, this.state.activeTrigger)
                              ? classes.triggerTextActive
                              : classes.triggerTextInactive
                          }
                        >
                          {prettyName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </div>
              )}
            ></Flags>
          </Grid>
          {proFeatures.includes(resource) && (
            <Flags authorizedFlags={['free']}>
              <Fab
                size="small"
                color="secondary"
                variant="extended"
                disabled={false}
                onClick={() => {
                  this.redirectToStripePortal();
                }}
              >
                Upgrade
              </Fab>
            </Flags>
          )}
          <Grid item xs={12}>
            <Grid item xs="auto">
              <Typography className={classes.subHeader}>{subHeader}</Typography>
            </Grid>
            <Grid container direction={'row'} spacing={1} item>
              {R.includes(index, this.state.activeTrigger) &&
                !R.isEmpty(this.state[resource]) && (
                  <Grid item xs={2}>
                    <Button
                      style={{ justifyContent: 'start' }}
                      onClick={() => {
                        this.removeResource(resource, index);
                      }}
                    >
                      <CancelRounded
                        className={classes.removeButton}
                      ></CancelRounded>
                    </Button>
                  </Grid>
                )}
              <Grid item xs={10}>
                <Typography className={classes.subtitle2}>
                  {resource === 'beacons'
                    ? R.pluck(
                        'name',
                        this.props.beacons.filter((x) =>
                          this.state.beacons.includes(x.objectId)
                        )
                      ).toString()
                    : this.state[resource]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderAudienceChannels() {}

  handleProduct = (product) => {
    console.log('the product is ', product);
  };

  renderTrigger() {
    const { classes } = this.props;
    const triggers = [
      {
        icon: (
          <LocationOnOutlined
            className={
              R.includes(0, this.state.activeTrigger)
                ? classes.triggerIconActive
                : classes.triggerIcon
            }
          ></LocationOnOutlined>
        ),
        onClick: this.handleNext,
        subHeader: 'Set tag location',
        prettyName: 'address',
        resource: 'address',
      },
      {
        icon: (
          <FontAwesomeIcon
            icon={faQrcode}
            className={
              R.includes(1, this.state.activeTrigger)
                ? classes.triggerIconActive
                : classes.triggerIcon
            }
          />
        ),
        onClick: this.handleNext,
        subHeader: 'LifeCode/Beacons',
        prettyName: 'beacons',
        resource: 'beacons',
      },
      {
        icon: (
          <AccessTimeOutlined
            className={
              R.includes(2, this.state.activeTrigger)
                ? classes.triggerIconActive
                : classes.triggerIcon
            }
          />
        ),
        onClick: this.handleNext,
        subHeader: 'Date/Time',
        prettyName: 'Schedule',
        resource: 'expirationString',
      },
      {
        icon: (
          <PermMediaOutlined
            className={
              R.includes(3, this.state.activeTrigger)
                ? classes.triggerIconActive
                : classes.triggerIcon
            }
          />
        ),
        onClick: this.handleNext,
        subHeader: 'Media',
        prettyName: 'Media',
        resource: 'media',
      },
      {
        icon: (
          <AccessTimeOutlined
            className={
              R.includes(4, this.state.activeTrigger)
                ? classes.triggerIconActive
                : classes.triggerIcon
            }
          />
        ),
        onClick: this.handleNext,
        subHeader: 'Schedule a push notification',
        prettyName: 'Push',
        resource: 'pushNotification',
      },
    ];

    return (
      <div>
        <Grid container direction="row">
          <Modal
            onClose={() => this.setState({ modalOpen: false })}
            aria-labelledby="simple-modal-title"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            aria-describedby="simple-modal-description"
            open={this.state.modalOpen}
          >
            <Products handleSubmit={this.handleProduct()}></Products>
          </Modal>
          {/* Trigger button column */}
          <Grid item xs={4}>
            <Grid container style={{ marginBottom: 18 }}>
              <Grid item xs="auto">
                <Typography className={classes.title}>
                  Triggers: Choose one or more
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row">
              {triggers.map((trigger, index) => {
                return this.triggerButton(trigger, index);
              })}
            </Grid>
          </Grid>
          {/* Trigger selection column */}
          <Grid item xs={8}>
            {((R.includes(0, this.state.activeTrigger) &&
              R.isEmpty(this.state.address)) ||
              this.state.currentActiveTrigger == 0) && (
              <Grid item xs="auto">
                <SearchMap
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places `}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div className={classes.blockquote} />}
                  mapElement={
                    <div style={{ height: `100%`, borderRadius: '7px' }} />
                  }
                  callback={this.setAddressText}
                />
              </Grid>
            )}
            {((R.includes(1, this.state.activeTrigger) &&
              R.isEmpty(this.state.beacons)) ||
              this.state.currentActiveTrigger == 1) && (
              <Grid item xs={'auto'}>
                {this.renderBeacons()}
              </Grid>
            )}
            {R.includes(2, this.state.activeTrigger) &&
              this.state.currentActiveTrigger == 2 && (
                <Grid item xs="auto">
                  <DatePickerComponent
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    callback={this.dateCallBack}
                  ></DatePickerComponent>
                </Grid>
              )}
            {((R.includes(3, this.state.activeTrigger) &&
              R.isEmpty(this.state.media)) ||
              this.state.currentActiveTrigger == 3) && (
              <Grid item xs="auto">
                <MultipleSelect
                  title="Media"
                  selectionArray={this.props.medias}
                  selectionKey="name"
                  multiple="false"
                  clear={this.clear}
                  handleChange={this.handleMediaChange}
                  weight={400}
                  color={grey[900]}
                  selectedItems={[]}
                />
              </Grid>
            )}
            {R.includes(4, this.state.activeTrigger) &&
              this.state.currentActiveTrigger == 4 && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs="auto">
                    <DateTimePicker
                      label=" Schedule Push Notification"
                      value={this.state.pushNotification}
                      onChange={(date) =>
                        this.handlePushNotificationChange(date)
                      }
                      style={{ width: '50%' }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              )}
          </Grid>
        </Grid>
      </div>
    );
  }

  renderConnectors(step) {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: '24px' }}>
          <Typography className={classes.title}>
            Connected Integrations
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {<UCIndex callBack={this.handleConnectorActionClick} step={step} />}
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '24px' }}>
          <Typography className={classes.subtitle}>
            Connect an account
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {<ConnectorIndex callBack={this.handleConnectorClick} step={step} />}
        </Grid>
      </Grid>
    );
  }

  renderAudience() {
    const { classes } = this.props;
    let activeConnector;

    const headerCells = [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'Email',
      },
    ];

    if (this.props.connectors) {
      activeConnector = this.props.connectors.find(
        (b) => b.id === this.state.activeConnectorAction?.id
      );

      //   R.find(
      //   R.propEq('id', this.state.activeConnectorAction?.id),
      //   this.props.connectors
      // );
    }

    return (
      <div>
        <Grid container>
          <Grid item xs="auto">
            <Typography className={classes.title}>Audience</Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 49 }}>
          <Grid container item xs="auto" spacing={1} direction={'column'}>
            <Grid item xs>
              <Typography className={classes.subtitle}>
                Default: Anyone who encounters the proximity trigger you will
                set up in the next step.
              </Typography>
            </Grid>
            {this.props.followers && this.props.followers.length > 0 && (
              <Grid item xs>
                <EnhancedTable
                  rows={this.props.followers}
                  callBack={this.setRecipients}
                  orderBy="name"
                  headerCells={headerCells}
                  selected={this.state.recipients}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Flags authorizedFlags={['beta']}>
          <Grid container direction={'row'}>
            <Grid item xs={4}>
              {this.renderConnectors('audience')}
            </Grid>
            <Grid item xs={8}>
              {this.state.activeStep === 0 &&
                this.state.activeConnectorAction &&
                activeConnector?.actionData?.audience &&
                this.renderAudienceTable(activeConnector?.actionData?.audience)}
            </Grid>
          </Grid>
        </Flags>
      </div>
    );
  }

  renderCreateContent() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container direction="row" spacing={2}>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography className={classes.title}>Messaging</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={this.handleAddNewMessage}
              >
                Add tag
              </Button>
            </Grid>
            <Grid container item xs={6} direction="column">
              <Grid item xs={3} alignItems="end">
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.showHeader}
                      onChange={this.onShowHeaderClick}
                    />
                  }
                  label="Show header?"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.preview}
                      onChange={() =>
                        this.setState({ preview: !this.state.preview })
                      }
                    />
                  }
                  label="Preview?"
                />
              </Grid>
            </Grid>

            <Flags authorizedFlags={['beta']}>
              <Grid item xs={12}>
                <UCIndex
                  callBack={this.handleConnectorActionClick}
                  step={'content'}
                ></UCIndex>
              </Grid>
            </Flags>
            <Grid container item>
              {this.state.lifeTags?.length > 0 && (
                <Grid container direction="row" item xs={2} spacing={2}>
                  {this.state.lifeTags.map((tag, index) => {
                    return (
                      <Grid item key={index} xs={12}>
                        <TagPreview
                          tag={tag}
                          // autoSize={true}
                          useMarkup={true}
                          useHtml={true}
                          showVertical={true}
                          showEditButton={false}
                          deleteTagButton={this.deleteLifeTag}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
              <Grid item xs={this.state.preview ? 6 : 9}>
                <EditorComponent
                  defaultValue={this.state.html}
                  setMessageText={this.setMessageText}
                ></EditorComponent>
              </Grid>
              {this.state.preview && (
                <Grid container item xs={3}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={4} container>
                    <Grid item xs="auto">
                      <IPhoneComponent
                        screen={
                          <Grid container>
                            <Grid item xs={12}>
                              <Show
                                tag={this.state}
                                style={{ width: '100%', height: '100%' }}
                                useMarkup={true}
                                useHtml={true}
                                mediaHeight="100%"
                                showHeader={this.state.showHeader}
                              />
                            </Grid>
                          </Grid>
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid xs={4} container>
            <Grid item xs="auto">
              <Typography fontFamily="Roboto Condensed" fontWeight="400">
                {this.state.fileName}
              </Typography>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </Grid>
      </div>
    );
  }

  renderPreview() {
    const { classes } = this.props;
    return (
      <div>
        <Grid direction="row" container spacing={0}>
          <Grid xs={5} item spacing={0}>
            <div>
              <Typography
                className={classes.title}
                style={{ marginBottom: 10 }}
              >
                Tag Preview
              </Typography>
              <IPhoneComponent
                screen={
                  <Grid item xs={12}>
                    <Show
                      tag={this.state.lifeTags[this.state.lifeTags.length - 1]}
                      style={{ width: '100%', height: '100%' }}
                      useMarkup={true}
                      useHtml={true}
                      mediaHeight="100%"
                      showHeader={this.state.showHeader}
                    />
                  </Grid>
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} style={{ marginBottom: 10 }}>
              Dashboard Preview
            </Typography>
            <TagPreview
              tag={this.state}
              useMarkup={true}
              useHtml={true}
              showEditButton={false}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  renderChannels() {
    return null;
  }

  renderMedia() {
    return (
      <MultipleSelect
        title="Media"
        selectionArray={this.props.medias}
        selectionKey="name"
        multiple="false"
        clear={this.clear}
        handleChange={this.handleMediaChange}
        weight={400}
        color={grey[900]}
        selectedItems={[]}
      />
    );
  }
  renderAudienceTable(rows) {
    const headerCells = [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
      },
      {
        id: 'phone',
        numeric: false,
        disablePadding: true,
        label: 'Phone Number',
      },
    ];

    return (
      <EnhancedTable
        rows={rows}
        callBack={this.setPhoneNumbere}
        orderBy="name"
        headerCells={headerCells}
        selected={this.state.phoneNumbers}
      />
      // <SearchTable
      // 	tableHead = { tableHead }
      // 	propsData={ rows }
      // 	searchPlaceHolder='Search'
      // 	callBack={ this.setPhoneNumber }
      // 	selected={ this.state.phoneNumbers }
      // />
    );
  }

  renderBeacons() {
    const headerCells = [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: 'Description',
      },
    ];

    console.log('this is the beacon state ', this.state.beacons);
    let beacons = [];
    for (let i = 0; i < this.state.beacons.length; i++) {
      let objectId = this.state.beacons[i];
      let beacon = this.props.beacons.find((b) => b.objectId == objectId);
      if (beacon) {
        beacons.push(beacon);
      }
    }
    return (
      <EnhancedTable
        rows={this.props.beacons}
        callBack={this.handleBeaconChange}
        orderBy="name"
        headerCells={headerCells}
        selected={beacons}
      />
    );
  }

  renderQrLocation() {
    return (
      <Typography>
        This message has a location and a QrCode. The user will see this message
        when they scan your QrCode and are within .10 miles of{' '}
        {this.state.address} This feature allows you to use the same QrCode with
        different content based on the location of the user who scans it.
      </Typography>
    );
  }

  CustomStepIcon(props) {
    if (props.active) {
      return (
        <div>
          <FontAwesomeIcon icon={faCircle} style={{ color: '#BF2420' }} />
        </div>
      );
    }
    if (props.completed) {
      return (
        <div>
          <CheckCircleOutline style={{ color: '#BF2420' }} />
        </div>
      );
    }

    return (
      <div
        style={{
          width: '12px',
          height: '12px',
          color: 'white',
          borderColor: '#243058',
          borderRadius: '50%',
          borderStyle: 'solid',
          borderWidth: 'thin',
        }}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const steps = ['Audience', 'Trigger', 'Content/Action', 'Preview', 'Done'];
    const stepSubTitles = [
      'Who will see your tag? Choose your audience.',
      'Attach your content to a location, LifeCode, media, beacon, date/time or any combination of these triggers to power up your content.',
      'Add content or choose action',
      'Preview your tags',
      'Tag Created',
    ];

    this.channels = this.renderChannels();

    if (!(this.state.address === '') && this.state.qrcode) {
      this.qrLocationText = this.renderQrLocation();
    } else {
      this.qrLocationText = <div></div>;
    }
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs="auto">
            <AddIcon className={classes.headerIcon}></AddIcon>
          </Grid>
          <Grid item xs="auto">
            <Typography className={classes.header}>Create a new tag</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.subtitleContainer}>
          <Grid item xs={10}>
            <Typography className={classes.subtitle}>
              {stepSubTitles[this.state.activeStep]}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Stepper
              activeStep={this.state.activeStep}
              classes={{ root: classes.stepper }}
              connector={
                <StepConnector
                  classes={{
                    line: classes.connector,
                    completed: classes.completedConnector,
                  }}
                />
              }
            >
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={this.CustomStepIcon.bind(this)}
                      StepIconProps={{
                        classes: {
                          root: classes.stepIconComponent,
                        },
                      }}
                    ></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          <Grid
            container
            item
            xs={6}
            alignContent={'center'}
            justifyContent={'center'}
            className={classes.buttonContainer}
          >
            {this.state.activeStep != 0 && (
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  className={classes.backButtons}
                  onClick={this.handleBack}
                >
                  Back
                </Button>
              </Grid>
            )}
            {this.state.activeStep != 3 && (
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  className={classes.nextButtons}
                  onClick={this.handleNext}
                  disabled={
                    (this.state.activeStep == 1 && !this.isValidDate()) ||
                    (this.state.activeStep == 2 &&
                      R.isEmpty(this.state.message) &&
                      R.isEmpty(this.state.html))
                  }
                >
                  Next
                </Button>
              </Grid>
            )}
            {this.state.activeStep == 3 && (
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  className={classes.submitButton}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {this.getStepComponents()}
      </div>
    );
  }
}

export default withStyles(styles)(New);
